import { combineReducers } from '@reduxjs/toolkit'
import basketProducts from './productsSlice'
import paymentSummary from './paymentSummarySlice'

const reducer = combineReducers({
    basketProducts,
    paymentSummary,
})

export default reducer
