import React, { memo, useMemo, lazy, Suspense, useEffect } from 'react'
import { Loading } from 'components/shared'
import { useSelector } from 'react-redux'
import { LAYOUT_TYPE_SIMPLE, LAYOUT_TYPE_BLANK } from 'constants/theme.constant'
import useAuth from 'utils/hooks/useAuth'
import useDirection from 'utils/hooks/useDirection'
import useLocale from 'utils/hooks/useLocale'

const Layout = () => {
    const layouts = {
        [LAYOUT_TYPE_SIMPLE]: lazy(() =>
            import('./SimpleLayouts/SWIK/SimpleLayout_SWIK')
        ),
        [LAYOUT_TYPE_BLANK]: lazy(() => import('./BlankLayout')),
    }

    const layoutType = useSelector((state) => state.theme.layout.type)

    const { authenticated } = useAuth()

    useDirection()

    useLocale()

    const AppLayout = useMemo(() => {
        if (authenticated || !authenticated) {
            return layouts[layoutType]
        }
        return lazy(() => import('./AuthLayout'))
    }, [layoutType, authenticated])

    return (
        <Suspense
            fallback={
                <div className="flex flex-auto flex-col h-[100vh]">
                    <Loading loading={true} />
                </div>
            }
        >
            <AppLayout />
        </Suspense>
    )
}

export default memo(Layout)
