import { combineReducers } from '@reduxjs/toolkit'
import session from './sessionSlice'
import user from './userSlice'
import prevCredForLinkingSlice from './prevCredForLinkingSlice'
const reducer = combineReducers({
    session,
    user,
    prevCredForLinkingSlice,
})

export default reducer
