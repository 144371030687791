import { createSlice } from '@reduxjs/toolkit'

const initialState = {}

export const currentProviderSlice = createSlice({
    name: 'truyu/currentProvider',
    initialState,
    reducers: {
        setAppProvider: (state, action) => {
            return {
                ...state,
                provider: action.payload,
            }
        },
    },
})

export const { setAppProvider } = currentProviderSlice.actions
export default currentProviderSlice.reducer
