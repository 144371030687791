import i18n from 'i18next'
import { initReactI18next } from 'react-i18next'

// ENGLISH

import enNavBar from './lang-swik/en/navbar.json'
import enFooter from './lang-swik/en/footer.json'
import enHome from './lang-swik/en/home.json'
import enProducts from './lang-swik/en/products.json'
import enProductsReports from './lang-swik/en/productsReport.json'
import enProductsAddress from './lang-swik/en/productsAddress.json'
import enCart from './lang-swik/en/cart.json'
import enAccount from './lang-swik/en/account.json'
import enThankyou from './lang-swik/en/thankyou.json'
import enAboutUs from './lang-swik/en/aboutUs.json'
import enEmailLangAlert from './lang-swik/en/emailLangAlert'
import enGdpr from './lang-swik/en/gdpr.json'
import enPrivacyPolicy from './lang-swik/en/privacyPolicy.json'
import enSignInSample from './lang-swik/en/signInSample.json'
import enSignUpSample from './lang-swik/en/signUpSample.json'
import enSupport from './lang-swik/en/support.json'
import enTermsAndConditions from './lang-swik/en/termsAndConditions.json'
import enForgotPasswordForm from './lang-swik/en/forgotPasswordForm.json'
import enResetPasswordForm from './lang-swik/en/resetPasswordFrom.json'
import enAffiliates from './lang-swik/en/affiliates.json'
import enContactUs from './lang-swik/en/contactUs.json'
import enUserDropdown from './lang-swik/en/userDropdown.json'
import enCodeVerifcation from './lang-swik/en/codeVerificationPopup.json'
import enSignInRequired from './lang-swik/en/signInRequired.json'

// ARABIC

import arNavBar from './lang-swik/ar/navbar.json'
import arFooter from './lang-swik/ar/footer.json'
import arHome from './lang-swik/ar/home.json'
import arProducts from './lang-swik/ar/products.json'
import arProductsReports from './lang-swik/ar/productsReport.json'
import arProductsAddress from './lang-swik/ar/productsAddress.json'
import arCart from './lang-swik/ar/cart.json'
import arAccount from './lang-swik/ar/account.json'
import arThankyou from './lang-swik/ar/thankyou.json'
import arAboutUs from './lang-swik/ar/aboutUs.json'
import arEmailLangAlert from './lang-swik/ar/emailLangAlert'
import arGdpr from './lang-swik/ar/gdpr.json'
import arPrivacyPolicy from './lang-swik/ar/privacyPolicy.json'
import arSignInSample from './lang-swik/ar/signInSample.json'
import arSignUpSample from './lang-swik/ar/signUpSample.json'
import arSupport from './lang-swik/ar/support.json'
import arTermsAndConditions from './lang-swik/ar/termsAndConditions.json'
import arForgotPasswordForm from './lang-swik/ar/forgotPasswordForm.json'
import arResetPasswordForm from './lang-swik/ar/resetPasswordFrom.json'
import arAffiliates from './lang-swik/ar/affiliates.json'
import arContactUs from './lang-swik/ar/contactUs.json'
import arUserDropdown from './lang-swik/ar/userDropdown.json'
import arCodeVerifcation from './lang-swik/ar/codeVerificationPopup.json'
import arSignInRequired from './lang-swik/ar/signInRequired.json'
// GERMAN
import deNavBar from './lang-swik/de/navbar.json'
import deFooter from './lang-swik/de/footer.json'
import deHome from './lang-swik/de/home.json'
import deProducts from './lang-swik/de/products.json'
import deProductsReports from './lang-swik/de/productsReport.json'
import deProductsAddress from './lang-swik/de/productsAddress.json'
import deCart from './lang-swik/de/cart.json'
import deAccount from './lang-swik/de/account.json'
import deThankyou from './lang-swik/de/thankyou.json'
import deAboutUs from './lang-swik/de/aboutUs.json'
import deEmailLangAlert from './lang-swik/de/emailLangAlert'
import deGdpr from './lang-swik/de/gdpr.json'
import dePrivacyPolicy from './lang-swik/de/privacyPolicy.json'
import deSignInSample from './lang-swik/de/signInSample.json'
import deSignUpSample from './lang-swik/de/signUpSample.json'
import deSupport from './lang-swik/de/support.json'
import deTermsAndConditions from './lang-swik/de/termsAndConditions.json'
import deForgotPasswordForm from './lang-swik/de/forgotPasswordForm.json'
import deResetPasswordForm from './lang-swik/de/resetPasswordFrom.json'
import deAffiliates from './lang-swik/de/affiliates.json'
import deContactUs from './lang-swik/de/contactUs.json'
import deUserDropdown from './lang-swik/de/userDropdown.json'
import deCodeVerifcation from './lang-swik/de/codeVerificationPopup.json'
import deSignInRequired from './lang-swik/de/signInRequired.json'
// FRENCH
import frNavBar from './lang-swik/fr/navbar.json'
import frFooter from './lang-swik/fr/footer.json'
import frHome from './lang-swik/fr/home.json'
import frProducts from './lang-swik/fr/products.json'
import frProductsReports from './lang-swik/fr/productsReport.json'
import frProductsAddress from './lang-swik/fr/productsAddress.json'
import frCart from './lang-swik/fr/cart.json'
import frAccount from './lang-swik/fr/account.json'
import frThankyou from './lang-swik/fr/thankyou.json'
import frAboutUs from './lang-swik/fr/aboutUs.json'
import frEmailLangAlert from './lang-swik/fr/emailLangAlert'
import frGdpr from './lang-swik/fr/gdpr.json'
import frPrivacyPolicy from './lang-swik/fr/privacyPolicy.json'
import frSignInSample from './lang-swik/fr/signInSample.json'
import frSignUpSample from './lang-swik/fr/signUpSample.json'
import frSupport from './lang-swik/fr/support.json'
import frTermsAndConditions from './lang-swik/fr/termsAndConditions.json'
import frForgotPasswordForm from './lang-swik/fr/forgotPasswordForm.json'
import frResetPasswordForm from './lang-swik/fr/resetPasswordFrom.json'
import frAffiliates from './lang-swik/fr/affiliates.json'
import frContactUs from './lang-swik/fr/contactUs.json'
import frUserDropdown from './lang-swik/fr/userDropdown.json'
import frCodeVerifcation from './lang-swik/fr/codeVerificationPopup.json'
import frSignInRequired from './lang-swik/fr/signInRequired.json'
// import appConfig from 'configs/app.config'
import { getAppConfig } from 'configs/app.config'

const appConfig = getAppConfig('en')

const resources = {
    en: {
        navbar: enNavBar,
        footer: enFooter,
        home: enHome,
        products: enProducts,
        productsReport: enProductsReports,
        productsAddress: enProductsAddress,
        cart: enCart,
        accounts: enAccount,
        gdpr: enGdpr,
        privacyPolicy: enPrivacyPolicy,
        signInSample: enSignInSample,
        signUpSample: enSignUpSample,
        termsAndConditions: enTermsAndConditions,
        support: enSupport,
        forgotPasswordForm: enForgotPasswordForm,
        resetPasswordForm: enResetPasswordForm,
        thankyou: enThankyou,
        aboutUs: enAboutUs,
        affiliates: enAffiliates,
        contactUs: enContactUs,
        userDropdown: enUserDropdown,
        codeVerificationPopup: enCodeVerifcation,
        emailLangAlert: enEmailLangAlert,
        signInRequired :enSignInRequired
    },
    ar: {
        navbar: arNavBar,
        footer: arFooter,
        home: arHome,
        products: arProducts,
        productsReport: arProductsReports,
        productsAddress: arProductsAddress,
        cart: arCart,
        accounts: arAccount,
        gdpr: arGdpr,
        privacyPolicy: arPrivacyPolicy,
        signInSample: arSignInSample,
        signUpSample: arSignUpSample,
        termsAndConditions: arTermsAndConditions,
        support: arSupport,
        forgotPasswordForm: arForgotPasswordForm,
        resetPasswordForm: arResetPasswordForm,
        thankyou: arThankyou,
        aboutUs: arAboutUs,
        affiliates: arAffiliates,
        contactUs: arContactUs,
        userDropdown: arUserDropdown,
        codeVerificationPopup: arCodeVerifcation,
        emailLangAlert: arEmailLangAlert,
        signInRequired :arSignInRequired
    },
    de: {
        navbar: deNavBar,
        footer: deFooter,
        home: deHome,
        products: deProducts,
        productsReport: deProductsReports,
        productsAddress: deProductsAddress,
        cart: deCart,
        accounts: deAccount,
        gdpr: deGdpr,
        privacyPolicy: dePrivacyPolicy,
        signInSample: deSignInSample,
        signUpSample: deSignUpSample,
        termsAndConditions: deTermsAndConditions,
        support: deSupport,
        forgotPasswordForm: deForgotPasswordForm,
        resetPasswordForm: deResetPasswordForm,
        thankyou: deThankyou,
        aboutUs: deAboutUs,
        affiliates: deAffiliates,
        contactUs: deContactUs,
        userDropdown: deUserDropdown,
        codeVerificationPopup: deCodeVerifcation,
        emailLangAlert: deEmailLangAlert,
        signInRequired :deSignInRequired
    },
    fr: {
        navbar: frNavBar,
        footer: frFooter,
        home: frHome,
        products: frProducts,
        productsReport: frProductsReports,
        productsAddress: frProductsAddress,
        cart: frCart,
        accounts: frAccount,
        gdpr: frGdpr,
        privacyPolicy: frPrivacyPolicy,
        signInSample: frSignInSample,
        signUpSample: frSignUpSample,
        termsAndConditions: frTermsAndConditions,
        support: frSupport,
        forgotPasswordForm: frForgotPasswordForm,
        resetPasswordForm: frResetPasswordForm,
        thankyou: frThankyou,
        aboutUs: frAboutUs,
        affiliates: frAffiliates,
        contactUs: frContactUs,
        userDropdown: frUserDropdown,
        codeVerificationPopup: frCodeVerifcation,
        emailLangAlert: frEmailLangAlert,
        signInRequired :frSignInRequired
    },
}

i18n.use(initReactI18next).init({
    resources,
    fallbackLng: appConfig?.locale,
    lng: appConfig?.locale,
    interpolation: {
        escapeValue: false,
    },
})

export const dateLocales = {
    en: () => import('dayjs/locale/en'),
    fr: () => import('dayjs/locale/fr'),
    de: () => import('dayjs/locale/de'),
    ar: () => import('dayjs/locale/ar'),
    es: () => import('dayjs/locale/es'),
    it: () => import('dayjs/locale/it'),
}

export default i18n
