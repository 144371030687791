import { AUTH_PREFIX_PATH } from 'constants/route.constant'

// let lang="en"
export function getAppConfig(lang) {
    let language = lang || 'en'

    const appConfig = {
        apiPrefix: '/api',
        // authenticatedEntryPath: '/home',
        // the authentication_required is for if user come on the signin page from buy product then we wil redirect user back to report-address page from where it come.
        authenticatedEntryPath:localStorage.getItem('authentication_required')?`/${language}/report-address`:`/${language}/home`,

        
        // unAuthenticatedEntryPath: `${AUTH_PREFIX_PATH}/${language}/account`, // Add the language parameter
        unAuthenticatedEntryPath:`/${language}/home`,
        tourPath: '/',
        locale: 'en',
        enableMock: false,
    }
    return appConfig
}

// export default appConfig
