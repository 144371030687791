import { useSelector } from 'react-redux'

function useAuth() {
    console.log('INSIDE OF USE AUTH')
    const user = useSelector((state) => state.auth.user)

    console.log('CURRENT USER', user)

    console.log(Object.keys(user).length > 0)
    return {
        authenticated: Object.keys(user).length > 0,
    }
}

export default useAuth
