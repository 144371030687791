import { createSlice } from '@reduxjs/toolkit'

const initialState = {}

export const appInstanceSlice = createSlice({
    name: 'truyu/appInstance',
    initialState,
    reducers: {
        setAppInstance: (state, action) => {
            return {
                ...state,
                app: action.payload,
            }
        },
    },
})

export const { setAppInstance } = appInstanceSlice.actions
export default appInstanceSlice.reducer
