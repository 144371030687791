import { createSlice } from '@reduxjs/toolkit'

const initialState = {
    
   
}

export const currentPurchaseSlice = createSlice({
    name: 'currentPurchase',
    initialState,
    reducers: {
        addCurrentPurchase: (state, action) => {
            const purchase = action.payload;
        
            return {
                ...state,
                purchase,
            };
        },
    

    
       
        
      
        removecurrentPurchase: (state) => {
            return {  }
        },
        

       

      

      
      
    },
})

export const {
    addCurrentPurchase,
    removecurrentPurchase
    
  
} = currentPurchaseSlice.actions
export default currentPurchaseSlice.reducer
