import { createSlice } from '@reduxjs/toolkit'

const initialState = {
    user_analytics_ui_data: {
        interests: {
            results: [],
        },
        demographics: {
            results: [],
        },
        activities: {
            results: [],
        },
    },
}

export const extensionUserAnalyticsSlice = createSlice({
    name: 'extension/user_analytics',
    initialState,
    reducers: {
        setUserAnalyticsDataIntoStore: (state, action) => {
            const newResult = {
                timeseries: action.payload.timeseries,
                data: action.payload.data,
                dt_stored: new Date(), // You can set the current datetime here
            }

            if (action.payload.collecName === 'demographics') {
                // Find the index of the existing result with the same timeseries
                const existingIndex =
                    state.user_analytics_ui_data.demographics.results.findIndex(
                        (result) =>
                            result.timeseries === action.payload.timeseries
                    )

                if (existingIndex !== -1) {
                    // If the timeseries already exists, update the existing object
                    state.user_analytics_ui_data.demographics.results[
                        existingIndex
                    ] = newResult
                } else {
                    // If the timeseries doesn't exist, add a new object to the array
                    state.user_analytics_ui_data.demographics.results.push(
                        newResult
                    )
                }
            }

            if (action.payload.collecName === 'interests') {
                // Find the index of the existing result with the same timeseries
                const existingIndex =
                    state.user_analytics_ui_data.interests.results.findIndex(
                        (result) =>
                            result.timeseries === action.payload.timeseries
                    )

                if (existingIndex !== -1) {
                    // If the timeseries already exists, update the existing object
                    state.user_analytics_ui_data.interests.results[
                        existingIndex
                    ] = newResult
                } else {
                    // If the timeseries doesn't exist, add a new object to the array
                    state.user_analytics_ui_data.interests.results.push(
                        newResult
                    )
                }
            }

            if (action.payload.collecName === 'activities') {
                // Find the index of the existing result with the same timeseries
                const existingIndex =
                    state.user_analytics_ui_data.activities.results.findIndex(
                        (result) =>
                            result.timeseries === action.payload.timeseries
                    )

                if (existingIndex !== -1) {
                    // If the timeseries already exists, update the existing object
                    state.user_analytics_ui_data.activities.results[
                        existingIndex
                    ] = newResult
                } else {
                    // If the timeseries doesn't exist, add a new object to the array
                    state.user_analytics_ui_data.activities.results.push(
                        newResult
                    )
                }
            }
        },

        clearUserAnalyticsDataIntoStore: () => initialState,
    },
})

export const {
    setUserAnalyticsDataIntoStore,
    clearUserAnalyticsDataIntoStore,
} = extensionUserAnalyticsSlice.actions
export default extensionUserAnalyticsSlice.reducer
