import { createSlice } from '@reduxjs/toolkit'

const initialState = {
    extensionInstalled: false,
    extensionPortIsAlive: false,
    activePorts: [],
}

export const extensionSlice = createSlice({
    name: 'extension/installed',
    initialState,
    reducers: {
        setExtensionInstalled: (state, action) => {
            state.extensionInstalled = action.payload
        },
        setExtensionPortIsAlive: (state, action) => {
            state.extensionPortIsAlive = action.payload
        },
        addActivePort: (state, action) => {
            // Need to debug this every time to avoid from duplicate ports
            state?.activePorts?.push(action?.payload)
        },
        removeActivePort: (state, action) => {
            // Filter out the port to remove by reference
            state.activePorts = state.activePorts.filter(
                (port) => port !== action.payload
            )
        },
        clearActivePorts: (state) => {
            state.activePorts = []
        },
    },
})

export const {
    setExtensionInstalled,
    setExtensionPortIsAlive,
    addActivePort,
    removeActivePort,
    clearActivePorts,
} = extensionSlice.actions
export default extensionSlice.reducer
