import { createSlice } from '@reduxjs/toolkit'

export const initialState = {}
export const userSlice = createSlice({
    name: 'auth/prevCredForLinking',
    initialState: {
        googleOneTabCredentials: null,
        signInWithGoogleCredentials: null,
        signInWithEmailAndPasswordCredentials: null,
        customJwtCredentials: null,
    },
    reducers: {
        setGoogleOneTabCredentials: (state, action) => {
            state.googleOneTabCredentials = action.payload
        },
        setSignInWithGoogleCredentials: (state, action) => {
            state.signInWithGoogleCredentials = action.payload
        },
        setSignInWithEmailAndPasswordCredentials: (state, action) => {
            state.signInWithEmailAndPasswordCredentials = action.payload
        },
        setCustomJwtCredentials: (state, action) => {
            state.customJwtCredentials = action.payload
        },
    },
})

export const {
    setGoogleOneTabCredentials,
    setSignInWithGoogleCredentials,
    setSignInWithEmailAndPasswordCredentials,
    setCustomJwtCredentials,
} = userSlice.actions

export default userSlice.reducer
