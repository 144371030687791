import { createSlice } from '@reduxjs/toolkit'

const initialState = {}

// Calculate total amount for each product
const calculateProductTotal = (product) => {
    return product?.price[0]?.value?.amount * product.quantity
}

// Calculate shipping fee for each product (10%)
const calculateShippingFee = (product) => {
    return 0.1 * calculateProductTotal(product)
}

// Calculate tax for each product (6%)
const calculateTax = (product) => {
    return 0.06 * calculateProductTotal(product)
}

export const paymentSummarySlice = createSlice({
    name: 'basket/products/paymentsSummary',
    initialState,
    reducers: {
        calculateProductTotalAmount: (state, action) => {
            const totalAmount = calculateProductTotal(action.payload)
            return {
                ...state,
                [action.payload.id]: {
                    ...action.payload,
                    totalAmount,
                },
            }
        },
        calculateProductShippingFee: (state, action) => {
            const shippingFee = calculateShippingFee(action.payload)
            return {
                ...state,
                [action.payload.id]: {
                    ...action.payload,
                    shippingFee,
                },
            }
        },
        calculateProductTaxFee: (state, action) => {
            const tax = calculateTax(action.payload)
            return {
                ...state,
                [action.payload.id]: {
                    ...action.payload,
                    tax,
                },
            }
        },
        calculatePaymentSummary: (state, action) => {
            const subTotal = action.payload.reduce(
                (total, product) => total + calculateProductTotal(product),
                0
            )
            const shippingFee = action.payload.reduce(
                (total, product) => total + calculateShippingFee(product),
                0
            )
            const tax = action.payload.reduce(
                (total, product) => total + calculateTax(product),
                0
            )
            const overallTotal = subTotal + shippingFee + tax

            const paymentSummary = {
                subTotal,
                shippingFee,
                tax,
                overallTotal,
            }
            return {
                ...state,
                paymentSummary,
            }
        },
        resetPaymentSummary: (state) => {
            return initialState
        },
    },
})

export const {
    calculateProductTotalAmount,
    calculateProductShippingFee,
    calculateProductTaxFee,
    calculatePaymentSummary,
} = paymentSummarySlice.actions
export default paymentSummarySlice.reducer
