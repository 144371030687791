import { useEffect } from 'react';

const useLanguageClass = (lang) => {
  useEffect(() => {
    // Remove the previous language class from the body
    const langClass = Array.from(document.body.classList).filter(cls => cls.startsWith('lang-'));
    if (langClass.length > 0) {
      document.body.classList.remove(...langClass);
    }
    // Add the new language class to the body
    document.body.classList.add(`lang-${lang}`);

    // Clean up the effect when the component unmounts
    return () => {
      document.body.classList.remove(`lang-${lang}`);
    };
  }, [lang]);
};

export default useLanguageClass;


