import React from 'react'
import ReactDOM from 'react-dom/client'
// import { I18nextProvider } from 'react-i18next'
// import i18n from './locales/index'
import './index.css'
import App from './App'
import reportWebVitals from './reportWebVitals'
import { GoogleOAuthProvider } from '@react-oauth/google'

import { PersistGate } from 'redux-persist/integration/react'
import { Provider } from 'react-redux'
import { BrowserRouter } from 'react-router-dom'
import store, { persistor } from './store'
import history from './history'
import MyApp from './App'

const root = ReactDOM.createRoot(document.getElementById('root'))

root.render(
    <GoogleOAuthProvider
        clientId={process.env.REACT_APP_GOOGLE_AUTH_PROVIDER_CLIENT_ID}
    >
        {' '}
        <Provider store={store}>
            <PersistGate loading={null} persistor={persistor}>
                <BrowserRouter history={history}>
                    <MyApp />
                </BrowserRouter>
            </PersistGate>
        </Provider>
    </GoogleOAuthProvider>
)


// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
