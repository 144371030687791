import { combineReducers } from '@reduxjs/toolkit'
import extension from './extensionSlice'
import extensionUserAnalyticsSlice from './extensionUserAnalyticsSlice'

const reducer = combineReducers({
    extension,
    extensionUserAnalyticsSlice,
})

export default reducer
