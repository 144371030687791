import { createSlice } from '@reduxjs/toolkit'
// import appConfig from 'configs/app.config'
import { getAppConfig } from 'configs/app.config'

let appConfig = getAppConfig("en")

const initialState = {
    currentLang: "en",
}

export const localeSlice = createSlice({
    name: 'locale',
    initialState,
    reducers: {
        setLang: (state, action) => {
            state.currentLang = action.payload
        },
    },
})

export const { setLang } = localeSlice.actions

export default localeSlice.reducer
