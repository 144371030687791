import React, { useEffect } from 'react'

import Theme from 'components/template/Theme'
import Layout from 'components/layout'
import { useDispatch, useSelector } from 'react-redux'
import useLanguageClass from 'utils/hooks/useLanguageClass'
import { App } from 'realm-web'
import { runClientDebug } from 'utils/runClientDebug'
// import mockServer from './mock'
import './locales'
// import runOneSignal from 'utils/runOneSignal'
import { init } from 'communication_engagement'
import { setAppInstance } from 'store/appInstance/appSlice'

// walletconnect imports

/**
 * Set enableMock(Default false) to true at configs/app.config.js
 * If you wish to enable mock api
 */
// if (environment !== 'production' && appConfig.enableMock) {
//     mockServer({ environment })

// console.log("")
// }

function MyApp() {
    // This is for debugging, this is how we can only enable console in dev mode and not display them in prod mode
    if (process.env.NODE_ENV||process.env.REACT_APP_ENV === 'development') {
        window.clientdebug = true
    }
    console.log("process.env.NODE_ENV ",  process.env.NODE_ENV )
    console.log("process.env.REACT_APP_ENV",process.env.REACT_APP_ENV)
    // for test
    // use below function when ever you want to use console, and pass console value in params.
    runClientDebug('dev_mode')
    const dispatch = useDispatch()
    const lang = useSelector((state) => state.locale.currentLang)

    const app = new App({ id: process.env.REACT_APP_APP_ID })

    app?.currentUser?.refreshCustomData()

    window.app = app

    useEffect(() => {
        // Here we are storing this appInstance into redux store
        dispatch(setAppInstance(app))
    }, [])

    // Initializing OneSignal SDK
    useEffect(() => {
        async function runOneSignal() {
            await init()
        }
        runOneSignal()
    }, [])

    // this is because when we reload the page then this component is rerender, so on reload we need to set the lang class to body
    useLanguageClass(lang)

    // below useEffect is for getting parent ref from the url if present
    useEffect(() => {
        function getLastPartOfUrl() {
            let refValue = ''

            // Example URL: domain.com?ref=asdfasd
            const queryParams = new URLSearchParams(window.location.search)
            if (queryParams.has('ref')) {
                refValue = queryParams.get('ref')
                refValue = 'a_aid=' + refValue
                return refValue
            }
            // Example URL: domain.com?a_aid=asdfasd
            else if (queryParams.has('a_aid')) {
                refValue = queryParams.get('a_aid')
                refValue = 'a_aid=' + refValue
                return refValue
            }

            // Example URL: domain.com#ref=asdfasd
            const hashParams = new URLSearchParams(
                window.location.hash.substring(1)
            )
            if (hashParams.has('ref')) {
                refValue = hashParams.get('ref')
                refValue = 'a_aid=' + refValue
                return refValue
            }
            // Example URL: domain.com#aid=asdfasd
            else if (hashParams.has('a_aid')) {
                refValue = hashParams.get('a_aid')
                refValue = 'a_aid=' + refValue
                return refValue
            }

            // Example URL: domain.com/ref/asdfasd
            const pathSegments = window.location.pathname.split('/')
            if (
                pathSegments.length >= 2 &&
                pathSegments[pathSegments.length - 2] === 'ref'
            ) {
                refValue = pathSegments[pathSegments.length - 1]
                refValue = 'a_aid=' + refValue
                return refValue
            }

            return refValue
        }
        let parentRef = getLastPartOfUrl()
        if (parentRef && process.env.REACT_APP_FORCE_REF_SIGNUP === 'TRUE') {
            localStorage.setItem('parentRef', parentRef)
        }
    }, [])

    // Adding a comment
    return (
        <div className="!bg-white">
            <Theme>
                <Layout />
            </Theme>
        </div>
    )
}

export default MyApp
